/* You can add global styles to this file, and also import other style files */
*::-webkit-scrollbar {
  width: 4px;
  height: 8px;
  border: 1px;
  border-radius: 8px;
}

*::-webkit-scrollbar-track {
  background: #f6f1f0;
}

*::-webkit-scrollbar-thumb {
  border: 1px;
  border-radius: 8px;
  background-color: #c1c1c1;
}

:root {
  --clr-combobox-pill-height: auto;
}
.label, a.label {
  display: block;
}

clr-datagrid clr-dg-cell,
clr-datagrid clr-dg-column,
clr-datagrid clr-dg-footer,
clr-datagrid clr-dg-pagination {
  color: black !important;
}